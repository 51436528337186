import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import './App.css';
import { Layout, Typography, Button, notification, Row, Col, Card, Popover } from 'antd';
import Headers from './Header'
import QRCode from 'qrcode.react'
import DonateTables from './DonateTables'
import Footers from './Footer'
// eslint-disable-next-line
const { Title, Text, Paragraph } = Typography;


function App() {
  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Router>
        <Headers />
        <Layout>
          <Layout style={{ padding: "24px" }}>
              <Switch>
                <Route path="/" exact component={Index} />
                <Route path="/Alipay" component={AlipayPage} />
                <Route path="/WeChatPay" component={WeChatPay} />
//                <Route path="/UnionPay" component={UnionPayPage} />
                <Route path="/BTC" component={BTCPage} />
                <Route path="/USDT" component={USDTPage} />
//                <Route path="/DOGE" component={DOGEPage} />
                <Route component={NoMatch} />
              </Switch>
          </Layout>
        </Layout>
      </Router>
      <Footers />
    </Layout>

  );
}

export default App;

  function Index() {
    return <div id="Index" >
      <Row gutter={40}>
        <Col xs={24} sm={24} md={24} lg={16} xl={10} xxl={12} >
          <Card title="赞助与付款" hoverable headStyle={{ fontSize: 20 }} bodyStyle={{ fontSize: 16 }}>
            <Paragraph>
              <Text>感谢您的支持。</Text>
              <ul>
                <li>目前接受的付款方式有 支付宝，微信，及爱发电平台</li>
                <li>赞助列表将展示在此处</li>
                <li>金额小于1元人民币的交易信息将不会记录。</li>
                <li>很难准时更新，但是我会尽可能避免遗漏。如果可以，请在赞助时备注昵称并标识为赞助。</li>
              </ul>
            </Paragraph>
          </Card>
          <br />
          
        </Col>
        <Col xs={24} sm={24} md={24} lg={16} xl={14} xxl={12}>
          <Card title="赞助列表" hoverable headStyle={{ fontSize: 20 }} bodyStyle={{ fontSize: 16 }}><br />
            <DonateTables />
          </Card>
        </Col>
      </Row>
    </div>
  }

function AlipayPage() {
  return <div id="Alipay">
    <Row gutter={48}>
      <AlipayCoupon />
      <AlipayOrder />
    </Row>
  </div>;
}

function AlipayOrder() {
  return <Col xs={48} sm={48} md={12} lg={12} xl={12} xxl={12}>
    <Card title="支付宝付款" hoverable headStyle={{ fontSize: 20 }} bodyStyle={{ fontSize: 16 }} align="center">
      <QRCode renderAs="svg" size={200} value="https://qr.alipay.com/00c01809qtt0c2uxrz4qp05" /><br /><br />
      <Popover content={
        <Text>
        请在赞助时备注昵称并标识为赞助。
        </Text>
      } >
        <Button type="primary" onClick={
          () => {
            // open alipay after 1s
            setTimeout(() => {
              window.open("https://qr.alipay.com/00c01809qtt0c2uxrz4qp05", "_blank")
          }
          , 1000)
          }
        } target="_blank" >点击下单</Button>
      </Popover>
      <br />

    </Card>
  </Col>
}

function AlipayCoupon() {
  return <Col xs={48} sm={48} md={12} lg={12} xl={12} xxl={12}>
    <Card title="支付宝红包" hoverable headStyle={{ fontSize: 20 }} bodyStyle={{ fontSize: 16 }} align="center">
      <QRCode renderAs="svg" size={200} value="QUmlc1x06696td2v0womxjkz2e5eQ0" /><br />
      <Text>打开支付宝扫描上方二维码或<br />在首页搜“ <Text type="danger" strong code copyable>1380937</Text> ”领红包</Text>  </Card><br />

  </Col>
}

class WeChatPay extends React.Component {
  render() {
    return (
      <div id="WeChatPay" >
        <Index />
      </div>
    )
  }
}

function UnionPayPage() {
  return <div id="UnionPay">
    <Row gutter={48}>
      <UnionPayCoupon />
      <UnionPay />
    </Row>
  </div>;
}
function BTCPage() {
  return <div id="BTC">
    <Row gutter={48}>
      <Col xs={48} sm={48} md={12} lg={12} xl={12} xxl={12}>
        <Card title="BTC 钱包" hoverable headStyle={{ fontSize: 20 }} bodyStyle={{ fontSize: 16 }} align="center">
          <QRCode renderAs="svg" size={200} value="bitcoin:37BuA3LbM3L6hwzNRnNxEaHL5hfj2E417f" /><br /><br />
            <Text strong code copyable>37BuA3LbM3L6hwzNRnNxEaHL5hfj2E417f</Text><br />
        </Card><br />
      </Col>
    </Row>
  </div>
  ;
}

function USDTPage() {
  return <div id="USDT">
    <Row gutter={48}>
      <Col xs={48} sm={48} md={12} lg={12} xl={12} xxl={12}>
        <Card title="USDT 钱包" hoverable headStyle={{ fontSize: 20 }} bodyStyle={{ fontSize: 16 }} align="center">
          <QRCode renderAs="svg" size={200} value="0x298b526007d31cb8c04faD6c85990569Cfb6D467" /><br /><br />
            <Text strong code copyable>0x298b526007d31cb8c04faD6c85990569Cfb6D467</Text><br />
              
        </Card><br />
      </Col>
    </Row>
  </div>;
}

function DOGEPage() {
  return <div id="DOGE">
    <Row gutter={48}>
      <Col xs={48} sm={48} md={12} lg={12} xl={12} xxl={12}>
        <Card title="DOGE 钱包" hoverable headStyle={{ fontSize: 20 }} bodyStyle={{ fontSize: 16 }} align="center">
          <QRCode renderAs="svg" size={200} value="TEDdFQ1ayoEf7r9DDno9DdaJgn2L5TuES6" /><br /><br />
            <Text strong code copyable>TEDdFQ1ayoEf7r9DDno9DdaJgn2L5TuES6</Text><br />
        </Card><br />
      </Col>
    </Row>
  </div>;
}

function UnionPayCoupon() {
  return <Col xs={48} sm={48} md={12} lg={12} xl={12} xxl={12}>
    <Card title="云闪付红包" hoverable headStyle={{ fontSize: 20 }} bodyStyle={{ fontSize: 16 }} align="center">
      <QRCode renderAs="svg" size={200} value="https://wallet.95516.com/s/wl/webV3/activity/vMarketing2/html/snsIndex.html?r=725aba45364e6cf48c6b044fb9069197" /><br /><br />
      <Button type="primary" href="https://wallet.95516.com/s/wl/webV3/activity/vMarketing2/html/snsIndex.html?r=725aba45364e6cf48c6b044fb9069197" target="_blank" >点击领取</Button>
    </Card><br />
  </Col>;
}

function UnionPay() {
  return <Col xs={48} sm={48} md={12} lg={12} xl={12} xxl={12}>
    <Card title="云闪付付款" hoverable headStyle={{ fontSize: 20 }} bodyStyle={{ fontSize: 16 }} align="center">
      <QRCode renderAs="svg" size={200} value="https://qr.95516.com/00010002/01012123571890753265718637730182" /><br />
      <Text>打开云闪付App、银行App<br />和热门App的首页扫码功能轻松支付</Text>
    </Card><br />
  </Col>;
}

class NoMatch extends React.Component {
  render() {
    const openNotificationWithIcon = type => {
      notification[type]({
        message: '错误',
        description: '找不到请求的URL ' + window.location.pathname,
      });
    };
    return (
      <div id="NoMatch" onAnimationStart={() => openNotificationWithIcon('error')}>
        <Index />
      </div>
    )
  }
}

