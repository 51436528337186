import React from 'react'

import { Layout } from 'antd';

const { Footer } = Layout;

class Footers extends React.Component {
    render() {
        var currentYear = new Date().getFullYear()
        return (
            <Footer style={{ textAlign: 'center' }}>
                Copyright © 2015 - {currentYear} <a href="https://uv.uy" target="_blank" rel="external noopener noreferrer">Edison Jwa</a>. All rights reserved.
            </Footer>
        )
    }
}

export default Footers
