import React, { useState } from "react";
// eslint-disable-next-line
import { BrowserRouter as Link, NavLink } from "react-router-dom";
import { Layout, Menu, Modal, Image } from 'antd';
import { HomeOutlined, AlipayOutlined, LinkOutlined, WechatOutlined, createFromIconfontCN, HeartOutlined } from '@ant-design/icons';

const { Header } = Layout;
const IconFont = createFromIconfontCN({
  scriptUrl: '//at.alicdn.com/t/font_1391414_3pgia1sjdte.js',
});

function Headers(){ 
    const { SubMenu } = Menu;
    const [isModalVisible, setIsModalVisible] = useState(false);
    const showModal = () => {
    setIsModalVisible(true);
  };
    const handleClose = () => {
    setIsModalVisible(false);
  };
    return (
    <Header className="header" style={{ padding: "0px", height: "64px", lineHeight: "64px" }}>
      <Menu theme="light" mode="horizontal" style={{ lineHeight: "64px" }} className="icons-list">
        <Menu.Item key="home">
          <NavLink to="/">
            <HomeOutlined /> 主页
          </NavLink>
        </Menu.Item>
        <Menu.Item key="Alipay">
          <NavLink to="/Alipay">
            <AlipayOutlined /> 支付宝
          </NavLink>
        </Menu.Item>
        <Menu.Item key="wechat" onClick={showModal}>
          <NavLink to="#">
            <WechatOutlined /> 微信
          </NavLink>
        </Menu.Item>
        {/* <Menu.Item key="Union">
          <NavLink to="/UnionPay" >
            <IconFont type="icon-unionpay" /> 银联
        </NavLink>
        </Menu.Item> */}
        <Menu.Item key="afdian" >
          <a href="https://afdian.net/@edisonjwa" target="_blank" rel="noopener noreferrer">
            <HeartOutlined /> 爱发电
          </a>
        </Menu.Item>

      </Menu>
      <Modal title="微信赞赏码" centered="true" open={isModalVisible} onOk={handleClose} onCancel={handleClose} destroyOnClose="true" cancelButtonProps={{ style: { display: 'none' } }} ><Image width="100%" alt="微信赞赏码" src="https://image.hit.moe/images/wechat.jfif" />使用赞赏码赞助、付款时，请备注您的个人信息，如昵称，留言等。又或者可以选择在扫码后与我联系。</Modal>
    </Header>
  );
  }

export default Headers
